import React from "react"

import ProduseVerziPillar from "../images/sortiment-sustenabil.jpg"
import AngajatiPillar from "../images/angajati-si-societate.jpg"
import EnergiePillar from "../images/energie-si-resurse.jpg"
import ProduseVerziPillarLabel from "../images/sortiment-sustenabil-label.png"
import AngajatiPillarLabel from "../images/angajati-si-societate-label.png"
import EnergiePillarLabel from "../images/energie-si-resurse-label.png"

import ArrowLeft from "../images/arrow-left-green.svg"
import ArrowRight from "../images/arrow-right-green.svg"

class Piloni extends React.Component {
  componentDidMount() {
    $("#pillars-carousel").slick({
      slidesToShow: 4,
      prevArrow: ".pillars-arrow-left",
      nextArrow: ".pillars-arrow-right",
      infinite: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    })
  }

  render() {
    return (
      <div className="pillars-container">
        <h1 className="text-success text-center akko-bold py-3">
          Piloni sustenabilitate
        </h1>
        <div className="position-relative">
          <img
            className="numbers-arrow pillars-arrow-left"
            src={ArrowLeft}
            alt=""
          />
          <div id="pillars-carousel">
            {this.props.hide != 1 ? (
              <div className="my-md-5 p-1">
                <div className="pillar-body">
                  <div className="pillar-header text-center">
                    <img src={ProduseVerziPillar} alt="" />
                  </div>
                  <div className="pillar-content p-3">
                    <div className="pillar-label my-4 px-3">
                      <img
                        className="mx-auto"
                        src={ProduseVerziPillarLabel}
                        alt=""
                      />
                    </div>
                    <div className="pillar-description akko-regular my-4 px-3">
                      <p>
                      Ne propunem să conservăm pe cât posibil resursele naturale ale Pământului în procesul de producție.
                      </p>
                    </div>
                    <a
                      className="btn btn-success akko-bold w-100 d-flex justify-content-center align-items-center"
                      href="/sortiment-sustenabil"
                    >
                      Afla detalii <span className="arrow-icon" />
                    </a>
                  </div>
                </div>
              </div>
            ) : null}

            {this.props.hide != 2 ? (
                <div className="my-md-5 p-1">
                  <div className="pillar-body">
                    <div className="pillar-header text-center">
                      <img className="mx-auto" src={EnergiePillar} alt="" />
                    </div>
                    <div className="pillar-content p-3">
                      <div className="pillar-label my-4 px-3">
                        <img src={EnergiePillarLabel} alt="" />
                      </div>
                      <div className="pillar-description akko-regular my-4 px-3">
                        <p>
                          Ne dorim să diminuăm cât mai mult impactul negativ al activității noastre asupra mediului înconjurător.
                        </p>
                      </div>
                      <a
                          className="btn btn-success akko-bold w-100 rounded-1 d-flex justify-content-center align-items-center"
                          href="/energie-clima-resurse"
                      >
                        Afla detalii <span className="arrow-icon" />
                      </a>
                    </div>
                  </div>
                </div>
            ) : null}


            {this.props.hide != 3 ? (
              <div className="my-md-5 p-1">
                <div className="pillar-body">
                  <div className="pillar-header text-center">
                    <img className="mx-auto" src={AngajatiPillar} alt="" />
                  </div>
                  <div className="pillar-content p-3">
                    <div className="pillar-label my-4 px-3">
                      <img src={AngajatiPillarLabel} alt="" />
                    </div>
                    <div className="pillar-description akko-regular my-4 px-3">
                      <p>
                      Ne propunem să contribuim la dezvoltarea durabilă a societății prin crearea unor politici incluzive de resurse umane.
                      </p>
                    </div>
                    <a
                      className="btn btn-success akko-bold w-100 d-flex justify-content-center align-items-center"
                      href="/angajati-societate"
                    >
                      Afla detalii <span className="arrow-icon" />
                    </a>
                  </div>
                </div>
              </div>
            ) : null}



          </div>
          <img
            className="numbers-arrow pillars-arrow-right"
            src={ArrowRight}
            alt=""
          />
        </div>
      </div>
    )
  }
}

export default Piloni
